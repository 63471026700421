export default [
    {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/dashboard',
    },
    {
        component: 'CNavItem',
        name: 'Users',
        to: '/users',
    },
    {
        component: 'CNavItem',
        name: 'Categories',
        to: '/categories',
    },
    {
        component: 'CNavItem',
        name: 'Landing Pages',
        to: '/landing-pages',
    },
]
